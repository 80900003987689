"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedCardsHeader = RelatedCardsHeader;
var react_1 = __importStar(require("react"));
var iconV2_1 = require("@shared/components/image/iconV2");
var opacity_1 = require("@shared/components/touchable/opacity");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var label_1 = require("../../utils/label");
function RelatedCardsHeader(_a) {
    var label = _a.label, onEditCards = _a.onEditCards;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(view_1.MBView, { row: true, fullWidth: true, centerVertically: true, paddingBottom: 1 },
        react_1.default.createElement(label_1.MBBlockLabel, { label: label }),
        react_1.default.createElement(view_1.MBView, { fill: true }),
        onEditCards && (react_1.default.createElement(opacity_1.MBTouchableOpacity, { onPress: onEditCards },
            react_1.default.createElement(iconV2_1.MBIconV2, { name: "plus-circle", size: (0, view_1.grid)(5), color: colors.foregroundActive })))));
}
