"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardInputBlockDate = CardInputBlockDate;
exports.MBCardInputBlockDate = MBCardInputBlockDate;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var use_layout_animation_1 = require("use-layout-animation");
var form_1 = require("@shared/components/blocks/contexts/form");
var view_1 = require("@shared/components/view");
var base_1 = require("../input/base");
var utils_1 = require("../input/utils");
var NoCodeInputBlockDate_1 = require("./NoCodeInputBlockDate");
var content_1 = require("./content");
var picker_1 = require("./picker");
var types_1 = require("./types");
function CardInputBlockDate(_a) {
    var workflowTemplateId = _a.workflowTemplateId, block = _a.block;
    if ((0, lodash_1.isNil)(workflowTemplateId)) {
        return react_1.default.createElement(MBCardInputBlockDate, { block: block });
    }
    else {
        return react_1.default.createElement(NoCodeInputBlockDate_1.NoCodeInputBlockDate, { block: block, workflowTemplateId: workflowTemplateId });
    }
}
function MBCardInputBlockDate(_a) {
    var block = _a.block;
    var _b = (0, react_1.useContext)(form_1.CardFormContext), values = _b.values, setFieldValue = _b.setFieldValue;
    var value = values[block.name];
    var containerRef = (0, react_1.useRef)();
    var _c = (0, react_1.useState)(), pickerMode = _c[0], setPickerMode = _c[1];
    (0, use_layout_animation_1.useImmediateLayoutAnimation)([pickerMode]);
    var handleOpenPicker = (0, react_1.useCallback)(function (mode) {
        setPickerMode(mode);
        react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss();
    }, []);
    var handleClosePicker = (0, react_1.useCallback)(function () { return setPickerMode(undefined); }, []);
    var handlePress = (0, react_1.useCallback)(function () {
        if (block.fieldType === 'time') {
            handleOpenPicker(types_1.PickerMode.Time);
        }
        else {
            handleOpenPicker(types_1.PickerMode.Date);
        }
    }, [handleOpenPicker, block.fieldType]);
    var onChange = (0, react_1.useCallback)(function (updatedValue) { return setFieldValue(block.name, updatedValue.start); }, [block.name, setFieldValue]);
    var renderRight = (0, react_1.useCallback)(function (inputBlock) {
        if (!value) {
            return null;
        }
        if (!!inputBlock.label) {
            return react_1.default.createElement(view_1.MBView, { paddingTop: 2 }, (0, utils_1.renderClearButton)(inputBlock, handleClosePicker));
        }
        return (0, utils_1.renderClearButton)(inputBlock);
    }, [value, handleClosePicker]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef, onPress: !!pickerMode ? handleClosePicker : handlePress },
            react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, renderRight: renderRight },
                react_1.default.createElement(content_1.MBCardInputBlockDateContent, { block: block, pickerMode: pickerMode, onOpenPicker: handleOpenPicker, onClosePicker: handleClosePicker }))),
        !!pickerMode && (react_1.default.createElement(picker_1.MBCardInputBlockDatePicker, { mode: pickerMode, block: block, container: containerRef, onChange: onChange, onClose: handleClosePicker }))));
}
