"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockQuestionTextArea = MBCardInputBlockQuestionTextArea;
var react_1 = __importStar(require("react"));
var form_1 = require("@shared/components/blocks/contexts/form");
var inputQuestion_1 = require("@shared/components/blocks/lib/inputQuestion");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var button_1 = require("@shared/components/button");
var autosize_1 = require("@shared/components/textInput/autosize");
var opacity_1 = require("@shared/components/touchable/opacity");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
function MBCardInputBlockQuestionTextArea(_a) {
    var fieldName = _a.fieldName, label = _a.label, trigger = _a.trigger, disabled = _a.disabled, bottomSheetRef = _a.bottomSheetRef, onChange = _a.onChange;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), sheetVisible = _b[0], setSheetVisible = _b[1];
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var value = values[fieldName];
    var _c = (0, react_1.useState)(value), text = _c[0], setText = _c[1];
    var hideSheet = (0, react_1.useCallback)(function () { return setSheetVisible(false); }, []);
    var hideSheetAndSave = (0, react_1.useCallback)(function () {
        setText(text);
        onChange(text);
        setSheetVisible(false);
    }, [onChange, text]);
    var showSheet = (0, react_1.useCallback)(function () { return setSheetVisible(true); }, []);
    (0, hooks_1.useExecuteOnTrigger)(trigger, showSheet);
    var editForm = (0, react_1.useMemo)(function () {
        return (react_1.default.createElement(view_1.MBView, { fill: true, padding: 2 },
            react_1.default.createElement(autosize_1.MBAutosizeTextInput, { style: { backgroundColor: colors.backgroundInactive, borderRadius: (0, view_1.grid)(1) }, onChangeText: setText, value: text, placeholder: (0, i18n_1.i18n)('card.blocks.question.answerPlaceholder'), autoFocus: true, padding: "normal" }),
            react_1.default.createElement(view_1.MBView, { paddingBottom: 2 }),
            react_1.default.createElement(button_1.MBButton, { title: (0, i18n_1.i18n)('common.ok'), onPress: hideSheetAndSave })));
    }, [colors.backgroundInactive, hideSheetAndSave, text]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(view_1.MBView, { paddingBottom: 2 },
            react_1.default.createElement(typography_1.MBTextBody1, { flex: true }, label)),
        (value === null || value === void 0 ? void 0 : value.length) > 0 && (react_1.default.createElement(opacity_1.MBTouchableOpacity, { disabled: disabled, onPress: showSheet },
            react_1.default.createElement(view_1.MBView, { style: inputQuestion_1.styles.textarea, paddingVertical: 2, paddingHorizontal: 1 },
                react_1.default.createElement(typography_1.MBTextBody1, null, value)))),
        sheetVisible && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { initialSize: "half", onClose: hideSheet, title: (0, i18n_1.i18n)('card.blocks.question.answerButtonLabel'), containerRef: bottomSheetRef, layout: {
                offset: { x: 0, y: 0 },
                minWidth: 320,
                minHeight: (0, view_1.grid)(48),
            } }, editForm))));
}
