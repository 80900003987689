"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBToast = MBToast;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var iconV2_1 = require("@shared/components/image/iconV2");
var gestures_1 = require("@shared/components/toast/gestures");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var config_1 = require("@shared/config");
var index_1 = require("@shared/scenes/auth/contentWrapper/index");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
function MBToast(_a) {
    var options = _a.options, state = _a.state, onDismiss = _a.onDismiss, onExit = _a.onExit;
    var layout = options.layout, style = options.style, message = options.message, duration = options.duration;
    var isDesktop = (0, index_1.useIsDesktop)();
    var isConfirmation = style === 'confirmation';
    var isWarning = style === 'warning';
    var isModal = style === 'modal';
    var isDefault = style === 'default';
    var isLongLayout = layout === 'long';
    var textColor = isWarning ? styles_1.Colors.v2.purple : styles_1.Colors.v2.black;
    var icon = getToastIcon(options);
    var opacity = (0, react_1.useRef)(new react_native_1.Animated.Value(1)).current;
    var scale = (0, react_1.useRef)(new react_native_1.Animated.Value(1)).current;
    var translateX = (0, react_1.useRef)(new react_native_1.Animated.Value(react_native_1.Dimensions.get('screen').width)).current;
    var autoDismissTimerRef = (0, react_1.useRef)(undefined);
    var animIn = (0, react_1.useRef)(react_native_1.Animated.timing(translateX, {
        toValue: 0,
        useNativeDriver: true,
        duration: 300,
        delay: config_1.Config.TOAST_DELAY_DURATION,
    })).current;
    var animOut = (0, react_1.useRef)(react_native_1.Animated.parallel([
        react_native_1.Animated.timing(opacity, {
            toValue: 0,
            useNativeDriver: true,
            duration: 200,
        }),
        react_native_1.Animated.timing(scale, {
            toValue: 0.9,
            useNativeDriver: true,
            duration: 200,
        }),
    ])).current;
    (0, react_1.useEffect)(function () {
        animIn.start();
        if (duration && !isModal) {
            autoDismissTimerRef.current = setTimeout(function () {
                animIn.stop();
                onDismiss();
            }, duration);
        }
        return function () {
            if (autoDismissTimerRef.current) {
                clearTimeout(autoDismissTimerRef.current);
            }
        };
    }, [duration, isModal, animIn, onDismiss]);
    var prevState = (0, hooks_1.usePrevious)(state);
    (0, react_1.useEffect)(function () {
        if (prevState !== 'anim-out' && state === 'anim-out') {
            clearTimeout(autoDismissTimerRef.current);
            animIn.stop();
            animOut.start(onExit);
        }
    }, [prevState, state, animIn, animOut, onExit]);
    var panHandlers = (0, gestures_1.useToastFling)(onExit, translateX).panHandlers;
    var containerStyle = [
        styles.container,
        isModal && styles.modalPosition,
        isConfirmation && styles.confirmationPosition,
        isWarning && styles.warningPosition,
        isDefault && styles.defaultPosition,
        isDesktop && !isModal && styles.nonModalPosition,
    ];
    var toastStyle = [
        styles.toast,
        isConfirmation && styles.confirmationToast,
        isWarning && styles.warningToast,
    ];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        isModal && react_1.default.createElement(view_1.MBView, { style: styles.modalBackdrop }),
        react_1.default.createElement(react_native_1.Animated.View, __assign({ style: [containerStyle, { opacity: opacity, transform: [{ scale: scale }, { translateX: translateX }] }] }, (!isModal && panHandlers)),
            react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: onDismiss, activeOpacity: 1, disabled: isDefault || isModal },
                react_1.default.createElement(view_1.MBView, { style: toastStyle, paddingHorizontal: 2 },
                    react_1.default.createElement(view_1.MBView, { row: true, centerVertically: true, style: [styles.toastContentRow] },
                        icon && (react_1.default.createElement(view_1.MBView, { paddingRight: 1 },
                            react_1.default.createElement(iconV2_1.MBIconV2, { name: icon, color: styles_1.Colors.v2.purple, size: (0, view_1.grid)(5) }))),
                        react_1.default.createElement(view_1.MBView, { flex: true, style: [styles.toastMessage, isLongLayout && styles.longToastMessage] },
                            react_1.default.createElement(typography_1.MBTextBody1, { color: textColor }, message)),
                        !isLongLayout && react_1.default.createElement(ToastActionButtons, { options: options, onDismiss: onDismiss })),
                    isLongLayout && (react_1.default.createElement(view_1.MBView, { row: true, centerVertically: true, style: styles.toastButtonRow },
                        react_1.default.createElement(ToastActionButtons, { options: options, onDismiss: onDismiss }))))))));
}
function ToastActionButtons(_a) {
    var _b, _c;
    var options = _a.options, onDismiss = _a.onDismiss;
    var actions = options.actions, layout = options.layout, style = options.style, _d = options.showClose, showClose = _d === void 0 ? true : _d;
    var isDesktop = (0, index_1.useIsDesktop)();
    var isDefault = style === 'default';
    var isLongLayout = layout === 'long';
    return (react_1.default.createElement(react_1.default.Fragment, null, actions === null || actions === void 0 ? void 0 :
        actions.map(function (action, index) { return (react_1.default.createElement(ToastActionButton, { key: index, action: action, onDismiss: onDismiss, isLast: index === actions.length - 1, isFirst: index === 0 })); }),
        isDesktop && isDefault && (react_1.default.createElement(react_native_1.TouchableOpacity, { style: styles.action, onPress: onDismiss, key: "close" },
            isLongLayout && showClose && (react_1.default.createElement(typography_1.MBTextBody1Bold, null, (_c = (_b = (0, i18n_1.i18n)('common.close')) === null || _b === void 0 ? void 0 : _b.toUpperCase()) !== null && _c !== void 0 ? _c : '')),
            !isLongLayout && react_1.default.createElement(iconV2_1.MBIconV2, { name: "exit-small", color: styles_1.Colors.v2.purple, size: (0, view_1.grid)(3) })))));
}
function ToastActionButton(_a) {
    var _b, _c;
    var action = _a.action, onDismiss = _a.onDismiss, isLast = _a.isLast, isFirst = _a.isFirst;
    var onPress = function () {
        var _a;
        (_a = action.onPress) === null || _a === void 0 ? void 0 : _a.call(action);
        onDismiss();
    };
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { style: [styles.action, isLast && styles.actionLastItem, isFirst && styles.actionFirstItem], onPress: onPress, disabled: !action.onPress }, action.label ? (react_1.default.createElement(typography_1.MBTextBody1Bold, null, (_c = (_b = action.label) === null || _b === void 0 ? void 0 : _b.toUpperCase()) !== null && _c !== void 0 ? _c : '')) : (action.element)));
}
function getToastIcon(options) {
    var icon = options.icon, style = options.style;
    if (!(0, lodash_1.isNil)(icon)) {
        return icon;
    }
    switch (style) {
        case 'confirmation':
            return 'check-filled';
        case 'warning':
            return 'remove-x';
        default:
            return null;
    }
}
var styles = react_native_1.StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        alignItems: 'center',
        zIndex: 1000000,
    },
    modalBackdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    toast: {
        width: (0, view_1.grid)(43),
        maxWidth: '90%',
        borderRadius: (0, view_1.grid)(1),
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: (0, view_1.grid)(0.5),
        shadowOpacity: 0.4,
        elevation: (0, view_1.grid)(0.5),
        backgroundColor: styles_1.Colors.v2.green10,
    },
    modalPosition: {
        top: '45%',
    },
    nonModalPosition: {
        width: 'auto',
        right: (0, view_1.grid)(2),
    },
    confirmationPosition: {
        top: (0, view_1.grid)(2) + styles_1.Constants.headerHeight,
    },
    confirmationToast: {
        backgroundColor: styles_1.Colors.v2.green,
    },
    warningPosition: {
        top: (0, view_1.grid)(2) + styles_1.Constants.headerHeight,
    },
    warningToast: {
        backgroundColor: styles_1.Colors.v2.actionTropicalRed,
    },
    defaultPosition: {
        bottom: (0, view_1.grid)(4),
    },
    toastContentRow: {
        minHeight: (0, view_1.grid)(7),
    },
    toastMessage: {
        paddingTop: (0, view_1.grid)(1.5),
        paddingBottom: (0, view_1.grid)(1.5),
    },
    longToastMessage: {
        paddingTop: (0, view_1.grid)(2),
        paddingBottom: (0, view_1.grid)(1),
    },
    toastButtonRow: {
        justifyContent: 'flex-end',
        paddingBottom: (0, view_1.grid)(0.5),
    },
    action: {
        height: (0, view_1.grid)(5),
        marginRight: (0, view_1.grid)(1),
        marginLeft: (0, view_1.grid)(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: (0, view_1.grid)(2),
    },
    actionFirstItem: {
        marginLeft: 0,
    },
    actionLastItem: {
        marginRight: 0,
    },
});
