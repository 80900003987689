"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBottomSheet = MBBottomSheet;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_native_safe_area_context_1 = require("react-native-safe-area-context");
var backdrop_1 = require("@shared/components/bottomSheet/backdrop");
var gestures_1 = require("@shared/components/bottomSheet/gestures");
var hooks_1 = require("@shared/components/bottomSheet/hooks");
var knob_1 = require("@shared/components/bottomSheet/knob");
var tweens_1 = require("@shared/components/bottomSheet/tweens");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
/**
 * This compensates for the spring animation overshoot distance and prevents showing the background when the view
 * is animated past its resting place due to overshoot. The value is determined by observation.
 */
var SPRING_OVERSHOOT_MARGIN = 20;
function MBBottomSheet(_a) {
    var children = _a.children, onClose = _a.onClose, title = _a.title, renderTitleBar = _a.renderTitleBar, _b = _a.initialSize, initialSize = _b === void 0 ? getDefaultInitialSize() : _b;
    var _c = (0, hooks_1.useDimensions)(), initialTop = _c.initialTop, fullTop = _c.fullTop;
    var keyboardHeight = (0, hooks_2.useKeyboardHeight)();
    var _d = (0, tweens_1.useFade)({ duration: 300, value: 0.5 }), opacity = _d.opacity, fadeIn = _d.fadeIn, fadeOut = _d.fadeOut;
    var _e = (0, tweens_1.useSlide)({ duration: 200, value: initialTop }), translateY = _e.translateY, slideUp = _e.slideUp, slideDown = _e.slideDown;
    var openAnim = (0, react_1.useRef)(react_native_1.Animated.parallel([fadeIn, slideUp])).current;
    var close = (0, react_1.useCallback)(function () { return react_native_1.Animated.parallel([fadeOut, slideDown]).start(onClose); }, [onClose, fadeOut, slideDown]);
    var _f = (0, gestures_1.useSwipeGesture)(initialTop, fullTop, close, function () { return openAnim.stop(); }, initialSize), top = _f.top, panHandlers = _f.panHandlers;
    var safeAreaInsets = (0, react_native_safe_area_context_1.useSafeAreaInsets)();
    var safeAreaInsetBottomPadding = !!keyboardHeight ? 0 : safeAreaInsets.bottom;
    (0, react_1.useEffect)(function () { return openAnim.start(); }, [openAnim]);
    var colorTheme = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var contentStyle = (0, react_1.useMemo)(function () { return (__assign({ backgroundColor: colorTheme.background, position: 'absolute', top: top, bottom: -SPRING_OVERSHOOT_MARGIN + keyboardHeight, paddingBottom: SPRING_OVERSHOOT_MARGIN + safeAreaInsetBottomPadding, borderTopLeftRadius: (0, view_1.grid)(2), borderTopRightRadius: (0, view_1.grid)(2), overflow: 'hidden', width: '100%', transform: [{ translateY: translateY }] }, (react_native_1.Platform.OS === 'web' && { cursor: 'default' }))); }, [translateY, top, keyboardHeight, colorTheme, safeAreaInsetBottomPadding]);
    var backdropStyle = (0, react_1.useMemo)(function () { return ({
        width: '100%',
        backgroundColor: colorTheme.background,
        bottom: 0,
        height: keyboardHeight,
        position: 'absolute',
    }); }, [keyboardHeight, colorTheme]);
    var titleBar = (0, hooks_1.useTitleBar)(title, renderTitleBar);
    return (react_1.default.createElement(backdrop_1.MBBottomSheetBackdrop, { onClose: close, opacity: opacity },
        !!keyboardHeight && react_1.default.createElement(react_native_1.View, { style: backdropStyle }),
        react_1.default.createElement(react_native_1.TouchableWithoutFeedback, { onPress: function (event) { return event.stopPropagation(); } },
            react_1.default.createElement(react_native_1.Animated.View, { style: contentStyle },
                react_1.default.createElement(view_1.MBView, { fill: true },
                    react_1.default.createElement(react_native_1.View, __assign({}, (react_native_1.Platform.OS !== 'web' && panHandlers)),
                        react_native_1.Platform.OS !== 'web' && react_1.default.createElement(knob_1.MBBottomSheetKnob, null),
                        titleBar),
                    children)))));
}
function getDefaultInitialSize() {
    if (react_native_1.Platform.OS === 'web') {
        return 'full';
    }
    else {
        return 'half';
    }
}
