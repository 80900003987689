"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNotificationBadge = MBNotificationBadge;
var React = __importStar(require("react"));
var text_1 = require("@shared/components/text");
var view_1 = require("@shared/components/view");
var styles_1 = require("./styles");
function toString(count) {
    if (count > 99) {
        return "99+";
    }
    return String(count);
}
/*
    Small red dot badge showing notifications. If the count is 100 or more,
    we simply show "99+"
*/
function MBNotificationBadge(_a) {
    var count = _a.count, style = _a.style, testID = _a.testID;
    return (React.createElement(view_1.MBView, { center: true, style: [style, styles_1.Styles.badge], testID: testID },
        React.createElement(text_1.MBText, { bold: true, color: "white", size: "small" }, toString(count))));
}
