"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockCards = MBCardInputBlockCards;
exports.resolveLinkedCards = resolveLinkedCards;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var form_1 = require("@shared/components/blocks/contexts/form");
var hooks_1 = require("@shared/components/blocks/lib/cards/hooks");
var field_1 = require("@shared/components/blocks/lib/field");
var base_1 = require("@shared/components/blocks/lib/input/base");
var sheets_1 = require("@shared/components/blocks/lib/inputCards/sheets");
var spinner_1 = require("@shared/components/loading/spinner");
var opacity_1 = require("@shared/components/touchable/opacity");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var RelatedCardsList_1 = require("./RelatedCardsList");
var preview_1 = require("./quantity/preview");
function MBCardInputBlockCardsContent(_a) {
    var block = _a.block, cards = _a.cards, loading = _a.loading, onEditCards = _a.onEditCards, onShowMore = _a.onShowMore, onRemoveCard = _a.onRemoveCard, onPressCard = _a.onPressCard, onChange = _a.onChange;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    // Hide the icon when there's at least one card
    var blockToRender = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isEmpty)(cards)) {
            return block;
        }
        else {
            return __assign(__assign({}, block), { icon: null });
        }
    }, [block, cards]);
    if (block.mode === 'quantity' && !(0, lodash_1.isEmpty)(cards)) {
        return (react_1.default.createElement(preview_1.MBCardQuantityPreview, { editable: true, cards: cards, label: block.label, onAdd: onEditCards, onPressCard: onPressCard, onChange: onChange }));
    }
    var content = (function () {
        if (loading) {
            return react_1.default.createElement(spinner_1.MBLoadingIndicator, { style: { alignItems: 'flex-start' } });
        }
        if ((0, lodash_1.isEmpty)(cards)) {
            return (0, field_1.defaultFieldPresenter)(block.placeholder, 'text', block.textStyle, colors.foregroundInactive);
        }
        return (react_1.default.createElement(RelatedCardsList_1.RelatedCardsList, { cards: cards, totalCardsNubmer: cards.length, block: block, onEditCards: onEditCards, onRemoveCard: onRemoveCard, onPressCard: onPressCard, onShowMore: onShowMore }));
    })();
    return (react_1.default.createElement(opacity_1.MBTouchableOpacity, { onPress: onEditCards },
        react_1.default.createElement(base_1.MBCardInputBlockBase, { block: blockToRender, renderLabel: null }, content)));
}
function MBCardInputBlockCards(_a) {
    var _b;
    var block = _a.block;
    var containerRef = (0, react_1.useRef)();
    var _c = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _c.setFieldValue, fieldValues = _c.values;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var cardItems = (0, hooks_1.useCardItems)((0, react_1.useMemo)(function () { return [block.name]; }, [block.name]));
    var workflowTemplate = (0, hooks_2.useWorkflowTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).workflowTemplate;
    var relatedChannelIds = (0, hooks_1.useRelatedChannels)(block.name).ids;
    var relatedCardComponent = getRelatedCardComponent(workflowTemplate, block);
    var selectedCardIds = cardItems === null || cardItems === void 0 ? void 0 : cardItems.map(function (item) { return item.id; });
    var summaryCards = (0, hooks_1.useGetSummaryCards)(selectedCardIds);
    var cards = resolveLinkedCards(summaryCards, cardItems);
    var _d = (0, react_1.useState)(false), showingPicker = _d[0], setShowingPicker = _d[1];
    var _e = (0, react_1.useState)(false), viewMode = _e[0], setViewMode = _e[1];
    var openLinkedCard = (0, hooks_1.useOpenLinkedCard)();
    var onChange = (0, react_1.useCallback)(function (updatedCards) {
        var defaultQuantity = block.mode === 'quantity' ? 1 : undefined;
        var updatedCardItems = updatedCards.map(function (item) {
            var _a;
            return ({
                id: item.cardId,
                quantity: (_a = item.quantity) !== null && _a !== void 0 ? _a : defaultQuantity,
            });
        });
        setFieldValue(block.name, (0, lodash_1.isEmpty)(updatedCardItems) ? null : updatedCardItems);
    }, [block, setFieldValue]);
    var onRemoveCard = (0, react_1.useCallback)(function (card) {
        onChange(cards.filter(function (item) { return item.cardId !== card.cardId; }));
    }, [cards, onChange]);
    var showPicker = (0, react_1.useCallback)(function () { return setShowingPicker(true); }, []);
    var hidePicker = (0, react_1.useCallback)(function () { return setShowingPicker(false); }, []);
    var onShowMore = (0, react_1.useCallback)(function () {
        setViewMode(true);
        showPicker();
    }, [showPicker]);
    var onEditCards = (0, react_1.useCallback)(function () {
        setViewMode(false);
        showPicker();
    }, [showPicker]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef }),
        react_1.default.createElement(MBCardInputBlockCardsContent, { block: block, cards: cards, loading: false, onEditCards: onEditCards, onChange: onChange, onRemoveCard: onRemoveCard, onPressCard: openLinkedCard, onShowMore: onShowMore }),
        showingPicker && (react_1.default.createElement(sheets_1.MBInputCardsBottomSheet, { block: block, fieldValues: fieldValues, initialRoute: viewMode ? [{ name: 'view' }] : null, max: (_b = block.validation) === null || _b === void 0 ? void 0 : _b.max, values: cards, onChange: onChange, onRemoveCard: onRemoveCard, onClose: hidePicker, containerRef: containerRef, targetChannelId: channel === null || channel === void 0 ? void 0 : channel.id, relatedChannelIds: relatedChannelIds, relatedCardComponent: relatedCardComponent }))));
}
function getRelatedCardComponent(workflowTemplate, block) {
    var component = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.find(function (workflowComponent) {
        return workflowComponent.id === block.name;
    });
    if ((0, hooks_2.isRelatedCardComponent)(component)) {
        return component;
    }
    return null;
}
function resolveLinkedCards(summaryCards, cardItems) {
    var cards = [];
    cardItems.forEach(function (item) {
        var summaryCard = summaryCards === null || summaryCards === void 0 ? void 0 : summaryCards.find(function (card) { return item.id === card.id; });
        if (!(0, lodash_1.isNil)(summaryCard)) {
            cards.push({
                cardId: summaryCard.id,
                name: summaryCard.name,
                quantity: item.quantity,
            });
        }
    });
    return cards;
}
