"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockDatePicker = MBCardInputBlockDatePicker;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var form_1 = require("@shared/components/blocks/contexts/form");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var comboDatePicker_1 = require("@shared/components/dateAndTime/comboDatePicker");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var hooks_1 = require("./hooks");
function MBCardInputBlockDatePicker(_a) {
    var block = _a.block, container = _a.container, onClose = _a.onClose, onChange = _a.onChange;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var defaultTime = (0, hooks_1.useDefaultTime)(block);
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var value = (0, hooks_1.useAsISOString)(values[block.name]);
    var datePickerValue = (0, react_1.useMemo)(function () { return ({ start: value }); }, [value]);
    var renderTitleBar = (0, react_1.useCallback)(function () {
        if (isDesktop) {
            return react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: block.label });
        }
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: block.label, rightIcon: "exit", rightIconColor: colors.foregroundActive, onRightIconPress: onClose }));
    }, [block, colors, onClose, isDesktop]);
    return (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { initialSize: "full", onClose: onClose, renderTitleBar: renderTitleBar, containerRef: container, layout: { offset: { x: isDesktop ? (0, view_1.grid)(10) : (0, view_1.grid)(2), y: 0 }, minHeight: 'fit-content' } },
        react_1.default.createElement(comboDatePicker_1.MBComboDatePicker, { autoFocus: block.autoFocus, label: block.label, mode: {
                date: 'single',
                time: block.fieldType === 'datetime' ? 'single' : undefined,
            }, defaultTime: defaultTime, onChange: onChange, value: datePickerValue })));
}
