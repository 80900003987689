"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_CARDS_TO_SHOW = void 0;
exports.MBCardsBlock = MBCardsBlock;
var react_1 = __importStar(require("react"));
var MBInlineCardList_1 = require("@shared/components/blocks/lib/cards/MBInlineCardList");
var hooks_1 = require("@shared/components/blocks/lib/cards/hooks");
var field_1 = require("@shared/components/blocks/lib/field");
var utils_1 = require("@shared/components/blocks/utils");
var opacity_1 = require("@shared/components/touchable/opacity");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var preview_1 = require("../inputCards/quantity/preview");
exports.MAX_CARDS_TO_SHOW = 6;
function MBCardsBlock(_a) {
    var block = _a.block, onPress = _a.onPress, renderLeft = _a.renderLeft, relatedCards = _a.relatedCards;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var fieldBlock = (0, react_1.useMemo)(function () { return (__assign(__assign({}, block), { name: null, type: 'field', fieldType: 'text' })); }, [block]);
    var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
    var cardItems = (0, hooks_1.useCardItems)(block.names);
    var cards = (0, hooks_1.joinRelatedCardsAndCardItems)(relatedCards, cardItems);
    var openLinkedCard = (0, hooks_1.useOpenLinkedCard)();
    if (block.mode === 'quantity' && !!cards.length) {
        return (react_1.default.createElement(opacity_1.MBTouchableOpacity, { onPress: onPress },
            react_1.default.createElement(preview_1.MBCardQuantityPreview, { label: block.label, cards: cards, onPressCard: openLinkedCard })));
    }
    return (react_1.default.createElement(field_1.MBFieldBlock, { value: null, block: fieldBlock, onPress: onPress, renderLeft: renderLeft, presenter: function () {
            if (cardItems.length === 0) {
                return (react_1.default.createElement(TypoComponent, { color: colors.foregroundInactive }, block.placeholder));
            }
            return (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(view_1.MBView, { row: true, wrap: true, style: { flexWrap: 'wrap' } },
                    react_1.default.createElement(MBInlineCardList_1.MBInlineCardList, { cards: cards === null || cards === void 0 ? void 0 : cards.slice(0, exports.MAX_CARDS_TO_SHOW), onPressCard: openLinkedCard, wrapCards: true })),
                cards.length > exports.MAX_CARDS_TO_SHOW && (react_1.default.createElement(typography_1.MBTextBody2, null, (0, i18n_1.i18n)('card.blocks.cardsInput.overflow', {
                    count: cards.length - exports.MAX_CARDS_TO_SHOW,
                })))));
        } }));
}
