"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBWebNavigationHeader = MBWebNavigationHeader;
var react_1 = __importStar(require("react"));
var separator_1 = require("@shared/components/list/separator");
var opacity_1 = require("@shared/components/touchable/opacity");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var headerButton_1 = require("./headerButton");
var hooks_1 = require("./hooks");
var styles_2 = require("./styles");
function MBWebNavigationHeader(_a) {
    var title = _a.title, onPress = _a.onPress, onPressLeft = _a.onPressLeft, onPressRight = _a.onPressRight, _b = _a.leftIcon, leftIcon = _b === void 0 ? require('@shared/assets/icons/v2/arrow-point-left.png') : _b, _c = _a.rightIcon, rightIcon = _c === void 0 ? require('@shared/assets/icons/v2/dots.png') : _c, _d = _a.separator, separator = _d === void 0 ? true : _d, rightButton = _a.rightButton;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var centerContent = (0, hooks_1.useCenterContent)({
        hasLeft: !!onPressLeft,
        hasRight: !!rightButton || !!onPressRight,
    });
    return (react_1.default.createElement(opacity_1.MBTouchableOpacity, { onPress: onPress },
        react_1.default.createElement(view_1.MBView, { center: true, style: [styles_2.Styles.header, { paddingHorizontal: centerContent.paddingHorizontal }] },
            !centerContent.loading &&
                (typeof title === 'string' ? (react_1.default.createElement(typography_1.MBTextH4, { color: colors.foregroundActive, numberOfLines: 1 }, title)) : (title)),
            !!onPressLeft && (react_1.default.createElement(view_1.MBView, { onSize: centerContent.leftOnSize, style: styles_2.Styles.leftIcon },
                react_1.default.createElement(headerButton_1.MBNavigationHeaderButton, { icon: leftIcon, onPress: onPressLeft }))),
            rightButton && (react_1.default.createElement(view_1.MBView, { style: styles_2.Styles.rightIcon, onSize: centerContent.rightOnSize }, rightButton)),
            !rightButton && !!onPressRight && (react_1.default.createElement(view_1.MBView, { style: styles_2.Styles.rightIcon, onSize: centerContent.rightOnSize },
                react_1.default.createElement(headerButton_1.MBNavigationHeaderButton, { icon: rightIcon, onPress: onPressRight })))),
        !!separator && react_1.default.createElement(separator_1.MBSeparator, null)));
}
