"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBottomDrawer = MBBottomDrawer;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_native_safe_area_context_1 = require("react-native-safe-area-context");
var dragHandle_1 = require("@shared/components/bottomDrawer/dragHandle");
var hooks_1 = require("@shared/components/bottomDrawer/hooks");
var gestures_1 = require("@shared/components/bottomSheet/gestures");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var RUBBER_BAND_DRAG_MAX = react_native_1.Dimensions.get('screen').height * 0.6;
var RUBBER_BAND_DIST_MAX = RUBBER_BAND_DRAG_MAX * 0.165;
function MBBottomDrawer(_a) {
    var anchor = _a.anchor, children = _a.children, discoveryKey = _a.discoveryKey;
    var colorTheme = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var keyboardHeight = (0, hooks_2.useKeyboardHeight)();
    var keyboardVisible = (0, hooks_2.useKeyboardVisible)();
    var keyboardVisibleRef = (0, hooks_2.usePersistentRef)(keyboardVisible);
    var _b = (0, react_1.useState)(false), measured = _b[0], setMeasured = _b[1];
    var _c = (0, react_1.useState)(0), anchorHeight = _c[0], setAnchorHeight = _c[1];
    var _d = (0, react_1.useState)(0), contentHeight = _d[0], setContentHeight = _d[1];
    var contentHeightRef = (0, hooks_2.usePersistentRef)(contentHeight);
    var modeRef = (0, react_1.useRef)('closed');
    var translateY = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    var _e = (0, hooks_1.useBottomDrawerDiscovery)(translateY, discoveryKey), setAsDiscovered = _e.setAsDiscovered, cancelDiscoveryAnimation = _e.cancel;
    var toOpen = (0, react_1.useCallback)(function () {
        setAsDiscovered();
        react_native_1.Animated.parallel([
            react_native_1.Animated.spring(translateY, {
                toValue: -contentHeightRef.current,
                useNativeDriver: false,
            }),
        ]).start(function () {
            modeRef.current = 'open';
        });
    }, [translateY, contentHeightRef, setAsDiscovered]);
    var toClosed = (0, react_1.useCallback)(function () {
        react_native_1.Animated.parallel([
            react_native_1.Animated.spring(translateY, {
                toValue: 0,
                useNativeDriver: false,
            }),
        ]).start(function () {
            modeRef.current = 'closed';
        });
    }, [translateY]);
    var panResponder = (0, react_1.useRef)(react_native_1.PanResponder.create({
        onPanResponderTerminationRequest: function () { return true; },
        onStartShouldSetPanResponder: function () { return false; },
        onMoveShouldSetPanResponder: function (_event, gestureState) {
            cancelDiscoveryAnimation();
            if (!(0, lodash_1.isNil)(keyboardVisibleRef.current)) {
                return false;
            }
            return Math.abs(gestureState.dy) >= 1 && Math.abs(gestureState.dx) < 2;
        },
        onPanResponderGrant: function () {
            translateY.stopAnimation();
            translateY.setOffset(translateY._value);
        },
        onPanResponderMove: function (_event, gestureState) {
            /*
            The gesture tracking operates in 2 modes: 1) direct and 2) rubber-band
              - When the drawer is dragged down towards the bottom edge of the screen, there is no need for rubber band effect, so just translate by the drag delta (dy)
              - When the drawer is dragged upwards, a breakpoint is used to enable the rubber band effect; any movement below the breakpoint is 1:1
              - When the breakpoint is exceeded, an easing function is applied to the distance moved *past* the breakpoint
              - The breakpoint is immediate (dy = 0) when the menu is open because it can't be dragged open any more
              - The breakpoint is the content height (dy = -contentHeight) when the menu is closed because that is how far the menu can be opened; past that it rubber bands (note: dragging up is negative value for dy)
             */
            var breakpoint = modeRef.current === 'open' ? 0 : -contentHeightRef.current;
            if (gestureState.dy >= breakpoint) {
                translateY.setValue(gestureState.dy);
            }
            else {
                var dist = RUBBER_BAND_DIST_MAX *
                    react_native_1.Easing.out(react_native_1.Easing.exp)(Math.min(Math.abs(gestureState.dy - breakpoint), RUBBER_BAND_DRAG_MAX) /
                        RUBBER_BAND_DRAG_MAX);
                var newVal = breakpoint + -dist;
                translateY.setValue(newVal);
            }
        },
        onPanResponderRelease: function (_event, gestureState) {
            var dy = gestureState.dy, vy = gestureState.vy;
            translateY.flattenOffset();
            var limit = contentHeightRef.current;
            if (modeRef.current === 'closed') {
                if (dy <= -limit / 2 || (0, gestures_1.didFlingUp)(vy)) {
                    if (keyboardVisibleRef.current) {
                        toClosed();
                    }
                    else {
                        toOpen();
                    }
                }
                else if (dy !== 0) {
                    react_native_1.Keyboard.dismiss();
                    toClosed();
                }
            }
            else {
                if (dy > limit / 2 || (0, gestures_1.didFlingDown)(vy)) {
                    react_native_1.Keyboard.dismiss();
                    toClosed();
                }
                else if (dy !== 0) {
                    toOpen();
                }
            }
        },
    })).current;
    (0, react_1.useEffect)(function () {
        toClosed();
    }, [keyboardVisible, toClosed]);
    var safeAreaInsets = (0, react_native_safe_area_context_1.useSafeAreaInsets)();
    var safeAreaInsetBottomPadding = keyboardVisible ? 0 : safeAreaInsets.bottom;
    var spacerStyle = (0, react_1.useMemo)(function () { return ({
        height: anchorHeight + dragHandle_1.DRAG_HANDLE_HEIGHT + keyboardHeight + safeAreaInsetBottomPadding, //spacerHeight,
        backgroundColor: colorTheme.background,
    }); }, [anchorHeight, colorTheme.background, keyboardHeight, safeAreaInsetBottomPadding]);
    var drawerStyle = (0, react_1.useMemo)(function () { return ({
        position: 'absolute',
        bottom: -contentHeight + keyboardHeight - RUBBER_BAND_DRAG_MAX + safeAreaInsetBottomPadding, //drawerBottom,
        width: '100%',
        shadowColor: styles_1.Colors.v2.black,
        shadowOpacity: 0.2,
        shadowOffset: { width: 2, height: 2 },
        shadowRadius: (0, view_1.grid)(1),
        elevation: (0, view_1.grid)(2),
        backgroundColor: colorTheme.background,
        borderTopLeftRadius: (0, view_1.grid)(2),
        borderTopRightRadius: (0, view_1.grid)(2),
        transform: [{ translateY: translateY }],
        paddingBottom: RUBBER_BAND_DRAG_MAX,
        opacity: Number(measured), // hide the drawer until the content height is measured
    }); }, [
        colorTheme.background,
        contentHeight,
        measured,
        keyboardHeight,
        translateY,
        safeAreaInsetBottomPadding,
    ]);
    var contentStyle = (0, react_1.useMemo)(function () { return ({
        opacity: keyboardVisible
            ? 0
            : translateY.interpolate({
                inputRange: [-contentHeight, 0],
                outputRange: [1, 0],
            }),
    }); }, [contentHeight, keyboardVisible, translateY]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.Animated.View, { style: spacerStyle }),
        react_1.default.createElement(react_native_1.Animated.View, __assign({}, panResponder.panHandlers, { style: drawerStyle }),
            react_1.default.createElement(dragHandle_1.MBBottomDrawerDragHandle, { visible: !keyboardVisible }),
            react_1.default.createElement(react_native_1.View, { onLayout: function (_a) {
                    var _b;
                    var nativeEvent = _a.nativeEvent;
                    return setAnchorHeight((_b = nativeEvent === null || nativeEvent === void 0 ? void 0 : nativeEvent.layout) === null || _b === void 0 ? void 0 : _b.height);
                } }, anchor),
            react_1.default.createElement(react_native_1.Animated.View, { onLayout: function (_a) {
                    var _b;
                    var nativeEvent = _a.nativeEvent;
                    setContentHeight((_b = nativeEvent === null || nativeEvent === void 0 ? void 0 : nativeEvent.layout) === null || _b === void 0 ? void 0 : _b.height);
                    setMeasured(true);
                }, style: contentStyle }, children))));
}
