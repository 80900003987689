"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBListHeader = MBListHeader;
var react_1 = __importStar(require("react"));
var text_1 = require("@shared/components/text");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
/*

    Note: padding horizontal is normal (to match with list item),
    padding top is normal (to align header with content below),
    padding bottom is small
*/
function MBListHeader(_a) {
    var title = _a.title, subtitle = _a.subtitle, backgroundColor = _a.backgroundColor, _b = _a.bold, bold = _b === void 0 ? true : _b;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(view_1.MBView, { padding: "normal", paddingBottom: "small", paddingTop: "large", style: { backgroundColor: backgroundColor } },
        !!title && (react_1.default.createElement(text_1.MBText, { bold: bold, size: "large", color: colors.foregroundActive }, title)),
        !!subtitle && (react_1.default.createElement(text_1.MBText, { size: "small", color: colors.foregroundActive }, subtitle))));
}
