"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBListRow = MBListRow;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var image_1 = require("@shared/components/image");
var icon_1 = require("@shared/components/image/icon");
var separator_1 = require("@shared/components/list/separator");
var spinner_1 = require("@shared/components/loading/spinner");
var opacity_1 = require("@shared/components/touchable/opacity");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
function MBListRowLeftContent(_a) {
    var content = _a.content, style = _a.style;
    if (!content) {
        return null;
    }
    // If the content is a custom component, return that.
    if (react_1.default.isValidElement(content)) {
        return react_1.default.createElement(view_1.MBView, { style: style !== null && style !== void 0 ? style : styles_2.Styles.image }, content);
    }
    // As default, assume that the content is an image
    // Note(fant): we don't check if what's being given as the image's
    // source is valid. But it can be safely inferred by the object type.
    return react_1.default.createElement(image_1.MBImage, { source: content, style: styles_2.Styles.image });
}
function MBListRowRightContent(_a) {
    var content = _a.content, isArrowIndicatorDefault = _a.isArrowIndicatorDefault, skipWrappingIcon = _a.skipWrappingIcon;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (!content && !isArrowIndicatorDefault) {
        return react_1.default.createElement(view_1.MBView, { style: styles_2.Styles.imageMargin });
    }
    // If there is no content and we want to show the arrow indicator (>) as default
    // (eg if we have an onPress handler), render that.
    if (!content && isArrowIndicatorDefault) {
        return (react_1.default.createElement(icon_1.MBIcon, { source: require('@shared/assets/icons/arrows/right.png'), style: styles_2.Styles.image, color: colors.foregroundInactive }));
    }
    // If the content is a custom component and we don't want to wrap it
    if (react_1.default.isValidElement(content) && skipWrappingIcon) {
        return content;
    }
    // If the content is a custom component and we want to wrap it (to limit width)
    if (react_1.default.isValidElement(content) && !skipWrappingIcon) {
        return react_1.default.createElement(view_1.MBView, { style: styles_2.Styles.image }, content);
    }
    // As default, assume that the content is an image
    return react_1.default.createElement(image_1.MBImage, { source: content, style: styles_2.Styles.image });
}
function MBListRowText(_a) {
    var children = _a.children, otherProps = __rest(_a, ["children"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (!children) {
        return null;
    }
    // If the content is a custom component, return that.
    if (react_1.default.isValidElement(children)) {
        return children;
    }
    // If the content is text, return that. Note: the content should always
    // be text if it even gets to this point (based on the type if content).
    if (typeof children === 'string') {
        return react_1.default.createElement(typography_1.MBTextBody1, __assign({ color: colors.foregroundActive, children: children }, otherProps));
    }
    return null;
}
function MBListRow(_a) {
    var style = _a.style, image = _a.image, imageStyle = _a.imageStyle, icon = _a.icon, title = _a.title, subtitle = _a.subtitle, onPress = _a.onPress, separator = _a.separator, isHidingPressableIndicator = _a.isHidingPressableIndicator, isLoading = _a.isLoading, isSelected = _a.isSelected, skipWrappingIcon = _a.skipWrappingIcon, _b = _a.paddingVertical, paddingVertical = _b === void 0 ? 0.5 : _b, _c = _a.paddingLeft, paddingLeft = _c === void 0 ? 3 : _c, _d = _a.paddingRight, paddingRight = _d === void 0 ? 1 : _d, testID = _a.testID, showChildren = _a.showChildren, children = _a.children;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var content = (react_1.default.createElement(view_1.MBView, { testID: testID, fullWidth: true },
        !!isSelected && (react_1.default.createElement(react_native_1.View, { style: [
                styles_1.SharedStyles.absoluteCover,
                styles_2.Styles.highlight,
                { backgroundColor: colors.highlightInactive },
            ] })),
        react_1.default.createElement(view_1.MBView, { row: true, center: true, fullWidth: true, paddingVertical: paddingVertical, paddingLeft: paddingLeft, paddingRight: paddingRight, style: { justifyContent: 'space-between' } },
            react_1.default.createElement(MBListRowLeftContent, { content: image, style: [styles_2.Styles.imageMargin, imageStyle] }),
            react_1.default.createElement(view_1.MBView, { flex: true },
                react_1.default.createElement(MBListRowText, { color: colors.foregroundActive }, title),
                subtitle && react_1.default.createElement(MBListRowText, { color: colors.foregroundInactive }, subtitle)),
            react_1.default.createElement(MBListRowRightContent, { content: isLoading ? react_1.default.createElement(spinner_1.MBLoadingIndicator, null) : icon, isArrowIndicatorDefault: !!onPress && !isHidingPressableIndicator, skipWrappingIcon: isLoading || skipWrappingIcon })),
        !!separator && react_1.default.createElement(separator_1.MBSeparator, null),
        showChildren && !!children && react_1.default.createElement(view_1.MBView, null, children)));
    // Note(fant): wrapping an MBTextInput in MBTouchableHighlight
    // causes issues when clicking space. That makes onPressIn
    // on MBTouchableHighlight be called, instead of adding a space
    // to the MBTextInput. Wrapping an MBListRow in an MBView instead
    // of a disabled MBTouchableHighlight is a workaround to this
    // problem. This is a problem eg in the task notes form field.
    if (!onPress) {
        return react_1.default.createElement(view_1.MBView, { style: [styles_2.Styles.root, style], children: content });
    }
    return (react_1.default.createElement(opacity_1.MBTouchableOpacity, { onPress: onPress, disabled: !onPress || isLoading, style: [
            styles_2.Styles.root,
            {
                backgroundColor: colors.listRow.background,
            },
            style,
        ], children: content }));
}
