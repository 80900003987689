"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFileDropListenerOverlay = MBFileDropListenerOverlay;
var ms = __importStar(require("milliseconds"));
var React = __importStar(require("react"));
var animation_1 = require("@shared/components/animation");
var icon_1 = require("@shared/components/image/icon");
var text_1 = require("@shared/components/text");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var styles_2 = require("./styles");
function MBFileDropListenerOverlay() {
    return (React.createElement(animation_1.MBAnimationFadeIn, { duration: ms.seconds(0.2), style: styles_2.Styles.overlayContainer },
        React.createElement(view_1.MBView, { row: true, center: true, style: styles_2.Styles.whiteBackground },
            React.createElement(icon_1.MBIcon, { noPadding: true, color: styles_1.Colors.v2.purple, source: require('@shared/assets/icons/v2/imagestack.png') }),
            React.createElement(text_1.MBText, { bold: true, color: styles_1.Colors.v2.purple }, (0, i18n_1.i18n)('form.dragAndDropToAttach'))),
        React.createElement(view_1.MBView, { style: [styles_1.SharedStyles.absoluteCover, styles_2.Styles.overlayBorder] })));
}
