"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSnoozeRow = MBSnoozeRow;
// @ts-strict-ignore
var React = __importStar(require("react"));
var icon_1 = require("@shared/components/image/icon");
var row_1 = require("@shared/components/list/row");
var typography_1 = require("@shared/components/typography");
var SnoozeUtil = __importStar(require("@shared/scenes/user/notificationSettings/util/snooze"));
var styles_1 = require("@shared/styles");
/*
    A row list item showing snooze status of a date. If the snoozeUntil date is
    set, text indicating when the snooze is expired is shown.
*/
function MBSnoozeRow(_a) {
    var snoozeUntil = _a.snoozeUntil, title = _a.title, titleWhenSnoozed = _a.titleWhenSnoozed, _b = _a.image, image = _b === void 0 ? require('@shared/assets/icons/v2/bell.png') : _b, _c = _a.imageWhenSnoozed, imageWhenSnoozed = _c === void 0 ? require('@shared/assets/icons/v2/bell-snoozed.png') : _c, _d = _a.color, color = _d === void 0 ? styles_1.Colors.v2.purple : _d, _e = _a.colorWhenSnoozed, colorWhenSnoozed = _e === void 0 ? styles_1.Colors.v2.purple : _e, _f = _a.titleColor, titleColor = _f === void 0 ? styles_1.Colors.v2.black : _f, _g = _a.titleColorWhenSnoozed, titleColorWhenSnoozed = _g === void 0 ? styles_1.Colors.v2.black : _g, otherProps = __rest(_a, ["snoozeUntil", "title", "titleWhenSnoozed", "image", "imageWhenSnoozed", "color", "colorWhenSnoozed", "titleColor", "titleColorWhenSnoozed"]);
    var isSnoozing = !!snoozeUntil;
    if (isSnoozing) {
        return (React.createElement(row_1.MBListRow, __assign({}, otherProps, { title: React.createElement(typography_1.MBTextBody1, { color: titleColorWhenSnoozed }, titleWhenSnoozed), image: React.createElement(icon_1.MBBoxedIcon, { source: imageWhenSnoozed, color: colorWhenSnoozed, backgroundColor: null }), skipWrappingIcon: true, subtitle: SnoozeUtil.formatUntil(snoozeUntil) })));
    }
    return (React.createElement(row_1.MBListRow, __assign({}, otherProps, { title: React.createElement(typography_1.MBTextBody1, { color: titleColor }, title), image: React.createElement(icon_1.MBBoxedIcon, { source: image, color: color, backgroundColor: null }) })));
}
