"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTodoItemRow = MBTodoItemRow;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var animation_1 = require("@shared/components/animation");
var form_1 = require("@shared/components/blocks/contexts/form");
var render_1 = require("@shared/components/blocks/contexts/render");
var theme_1 = require("@shared/components/blocks/contexts/theme");
var clear_1 = require("@shared/components/button/clear");
var iconV2_1 = require("@shared/components/image/iconV2");
var spinner_1 = require("@shared/components/loading/spinner");
var autosize_1 = require("@shared/components/textInput/autosize");
var opacity_1 = require("@shared/components/touchable/opacity");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var hooks_1 = require("@shared/util/permissions/hooks");
var hooks_2 = require("./hooks");
function MBTodoItemRow(_a) {
    var _this = this;
    var todo = _a.todo, block = _a.block, onRemove = _a.onRemove, onUpdate = _a.onUpdate;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var cardTheme = (0, react_1.useContext)(theme_1.CardThemeContext);
    var completed = todo.status === 'COMPLETED';
    var _b = (0, react_1.useState)(false), loading = _b[0], setLoading = _b[1];
    var channelId = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext).channelId;
    var updatePermission = (0, hooks_1.usePermission)({ action: 'update', subject: 'Card', channelId: channelId });
    var isSubmitting = (0, react_1.useContext)(form_1.CardFormContext).isSubmitting;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var handleClear = (0, react_1.useCallback)(function () { return onRemove(todo.id); }, [onRemove, todo.id]);
    var handleChangeText = (0, react_1.useCallback)(function (summary) { return onUpdate(__assign(__assign({}, todo), { summary: summary })); }, [onUpdate, todo]);
    var handleToggleStatus = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var maybePromise;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    maybePromise = onUpdate(__assign(__assign({}, todo), { status: todo.status === 'COMPLETED' ? 'NEEDS-ACTION' : 'COMPLETED' }));
                    if (!(maybePromise instanceof Promise)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, maybePromise];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [onUpdate, todo]);
    var scrollTextInputIntoView = (0, hooks_2.useScrollTextInputIntoView)();
    return (react_1.default.createElement(view_1.MBView, { row: true, paddingLeft: 2, paddingRight: 3 },
        react_1.default.createElement(opacity_1.MBTouchableOpacity, { disabled: isSubmitting || !updatePermission.enabled, onPress: handleToggleStatus },
            react_1.default.createElement(view_1.MBView, { paddingHorizontal: 1, style: isSubmitting && { opacity: 0.7 } },
                completed ? (react_1.default.createElement(animation_1.MBAnimationBounceIn, null,
                    react_1.default.createElement(iconV2_1.MBIconV2, { name: "task-filled", color: cardTheme === 'stale' ? colors.foregroundInactive : colors.accent }))) : (react_1.default.createElement(iconV2_1.MBIconV2, { name: "unfilled-circle", color: cardTheme === 'stale' ? colors.foregroundInactive : colors.accentInactive })),
                loading && (react_1.default.createElement(spinner_1.MBLoadingIndicator, { absolute: true, center: true, color: colors.accent, style: { backgroundColor: styles_1.Colors.overlay.white70 } })))),
        react_1.default.createElement(view_1.MBView, { paddingRight: renderContext === 'form' ? 1 : 0 }),
        block.mode === 'completion' ? (react_1.default.createElement(view_1.MBView, { flex: true, centerVertically: true, paddingVertical: 0.5 },
            react_1.default.createElement(typography_1.MBTextBody1, { lineThrough: completed, color: completed ? colors.foregroundInactive : colors.foregroundActive }, todo.summary))) : (react_1.default.createElement(react_1.default.Fragment, null,
            isDesktop && (react_1.default.createElement(autosize_1.MBAutosizeTextInput, { value: todo.summary, returnKeyType: "done", padding: "none", blurOnSubmit: true, placeholder: (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), color: completed ? colors.foregroundInactive : colors.foregroundActive, onFocus: scrollTextInputIntoView, onChangeText: handleChangeText, onSubmitEditing: react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss, style: {
                    flex: 1,
                    alignSelf: 'center',
                    marginTop: 4,
                    marginBottom: 4,
                    textDecorationLine: completed ? 'line-through' : 'none',
                } })),
            !isDesktop && (react_1.default.createElement(view_1.MBView, { style: { flex: 1, alignSelf: 'center' } },
                react_1.default.createElement(react_native_1.TextInput, { value: todo.summary, returnKeyType: "done", blurOnSubmit: true, textAlignVertical: 'center', placeholder: (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), onFocus: scrollTextInputIntoView, onChangeText: handleChangeText, onSubmitEditing: react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss, autoFocus: block.autoFocus, multiline: true, style: [
                        typography_1.MBTypographyStyles.body1,
                        {
                            textDecorationLine: completed ? 'line-through' : 'none',
                            color: completed ? colors.foregroundInactive : colors.foregroundActive,
                            paddingTop: 0,
                            paddingBottom: 0,
                        },
                    ] }))))),
        block.mode !== 'completion' && react_1.default.createElement(clear_1.MBClearButton, { onPress: handleClear })));
}
