"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBImage = MBImage;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
/*
    Override for basic image component. One challenge we have is importing
    assets and sharing that across web and native. Native wants PNGs and
    web wants SVGs. In the code where we use the MBImage (and thus require
    the image resource), we want to do the same call.

    Native wants to import like "require('@shared/assets/imageName.png')"
    On web, we therefore do:
    1. All assets are generated from SVGs into React components (using SVGR),
       which are smartly named "imageName.png.js". So they can be required like
       "require('@shared/assets/imageName.png')", just like on native.
    2. When we render these required images (eg not images with a source like
       {{ uri: 'http://somewhere.com/image.png' }} ), we want to render the
       React component.
    3. The React component is the default export from the source ( <source.default/> )
    4. We wrap it in a view with holds the styles we give the component.

    This does not interfere with the other SVG rendering we do on web.
    Also, it's easy to tint SVGs (aka use the same SVG with different
    colors in different places)
*/
function MBImage(_a) {
    var fill = _a.fill, style = _a.style, source = _a.source, tintColor = _a.tintColor, otherProps = __rest(_a, ["fill", "style", "source", "tintColor"]);
    // If we're requesting a local resource, we do this black magic. Explained above
    if (!!source &&
        !!source.default &&
        typeof source.default === 'function') {
        var Content = source.default;
        return (React.createElement(view_1.MBView, { style: style, fill: fill },
            React.createElement(Content, { style: { width: '100%', height: '100%' }, color: tintColor })));
    }
    // This default return looks the same as in index.js
    return (React.createElement(react_native_1.Image, __assign({ style: [fill && styles_1.SharedStyles.fill, style], source: source }, otherProps)));
}
