"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveNewViewSubMenu = SaveNewViewSubMenu;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var button_1 = require("@shared/components/button");
var textInput_1 = require("@shared/components/textInput");
var toast_1 = require("@shared/components/toast");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var config_1 = require("@shared/config");
var config_2 = require("@shared/scenes/workflows/config");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var workflow_1 = require("@shared/util/workflow");
var channel_1 = require("../blocks/contexts/channel");
var ControlsProvider_1 = require("./state/ControlsProvider");
var mapStateToCollectionCriteria_1 = require("./state/mapStateToCollectionCriteria");
function SaveNewViewSubMenu(_a) {
    var _this = this;
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var channelSlug = channel === null || channel === void 0 ? void 0 : channel.slug;
    var navigator = (0, navigation_1.useNavigator)();
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), isSaving = _b[0], setIsSaving = _b[1];
    var _c = (0, react_1.useState)("".concat(viewTemplate.name, " ").concat((0, i18n_1.i18n)('card.actions.save.saveAsNew.copy'))), name = _c[0], setName = _c[1];
    var _d = (0, react_1.useState)(''), nameError = _d[0], setNameError = _d[1];
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var createViewTemplate = (0, hooks_1.useCreateViewTemplate)(workflowTemplate.id);
    var _e = (0, ControlsProvider_1.useControlsState)(), filters = _e.filters, sorts = _e.sorts, groups = _e.groups;
    var revertToSaved = (0, ControlsProvider_1.useControlsActions)().revertToSaved;
    var isNameValid = !(0, lodash_1.isEmpty)(name.trim());
    var canSubmit = isNameValid && !(0, lodash_1.isNil)(channelSlug);
    (0, react_1.useEffect)(function () {
        if (!isNameValid) {
            setNameError((0, i18n_1.i18n)('card.actions.save.saveAsNew.fieldError'));
        }
        else {
            setNameError('');
        }
    }, [isNameValid]);
    var onSaveClicked = function () { return __awaiter(_this, void 0, void 0, function () {
        var collectionCriteria, defaultListViewOptions, componentsViewOptions, prevProperties, newViewTemplate, result, id_1, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!canSubmit) {
                        return [2 /*return*/];
                    }
                    setIsSaving(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    collectionCriteria = (0, mapStateToCollectionCriteria_1.mapStateToCollectionCriteria)({ filters: filters, sorts: sorts, groups: groups });
                    defaultListViewOptions = (0, config_2.getDefaultListViewComponentIds)(workflowTemplate);
                    componentsViewOptions = defaultListViewOptions.map(function (viewOption) {
                        return {
                            componentId: viewOption.componentId,
                            overrides: !(0, lodash_1.isNil)(viewOption.overrides) ? (0, workflow_1.toComponentOneOf)(viewOption.overrides) : null,
                        };
                    });
                    prevProperties = (0, lodash_1.omit)(viewTemplate, 'id', 'createdAt', 'updatedAt', 'cardUITemplate');
                    newViewTemplate = __assign(__assign({}, prevProperties), { name: name.trim(), type: viewTemplate.type, workflowTemplateId: workflowTemplate.id, collectionCriteria: collectionCriteria, componentsViewOptions: componentsViewOptions });
                    return [4 /*yield*/, createViewTemplate(newViewTemplate)];
                case 2:
                    result = _b.sent();
                    id_1 = (_a = result.data) === null || _a === void 0 ? void 0 : _a.createViewTemplate.id;
                    if ((0, lodash_1.isNil)(id_1)) {
                        throw new Error('Failed to create view template');
                    }
                    toast.show({
                        message: (0, i18n_1.i18n)('card.actions.save.saveAsNew.success', { name: name.trim() }),
                        duration: config_1.Config.TOAST_DEFAULT_DURATION,
                    });
                    // remove the stored modifications in the previous view template
                    revertToSaved();
                    requestAnimationFrame(function () {
                        navigator.navigate('ChannelCardList', { channelSlug: channelSlug, templateSlug: id_1 });
                    });
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _b.sent();
                    toast.show({
                        style: 'warning',
                        message: (0, i18n_1.i18n)('errors.somethingWentWrong'),
                        duration: config_1.Config.TOAST_DEFAULT_DURATION,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setIsSaving(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement(view_1.MBView, { style: { width: 280, paddingHorizontal: (0, view_1.grid)(3), paddingVertical: (0, view_1.grid)(3) } },
        react_1.default.createElement(typography_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('card.actions.save.saveAsNew.fieldLabel')),
        react_1.default.createElement(view_1.MBView, { style: { marginVertical: (0, view_1.grid)(1) } },
            react_1.default.createElement(textInput_1.TextInput, { value: name, onChangeText: setName, autoFocus: true, error: nameError })),
        react_1.default.createElement(button_1.MBButton, { loading: isSaving, disabled: !canSubmit, style: { marginTop: (0, view_1.grid)(1) }, onPress: onSaveClicked, title: (0, i18n_1.i18n)('card.actions.save.saveAsNew.buttonLabel') })));
}
