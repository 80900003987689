"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBHover = MBHover;
var react_1 = __importStar(require("react"));
var view_1 = require("@shared/components/view");
/*
    Wrapper component that listens to mouse hover state. Different props
    exist to act on hover events. Eg render overlays, callbacks etc.
    Useful for crafting hover experiences without needing too much state.


    The 2 basic use cases:

    <MBHover hoverContent={<ChildredThatShouldShowOnHover />}>
      <ChildrenThatShouldBeHovered />
    </MBHover>

    or

    <MBHover
      onHoverStart={() => setLocalHoveredState(true)}
      onHoverEnd={() => setLocalHoveredState(false)}
    >
      <ChildrenThatShouldBeHovered />
    </MBHover>

    Note: it's important that hoverContent is inside the hovering
    div, otherwise onMouseLeave would be called when hovering that
    content, even though it might be absolutely positioned intop
    of the actual div.

    Note: it's very important that
    - content that should NOT be rerendered on hover be passed as children (NOT a function component that takes a `hovering` prop)
    - content that SHOULD be rendered on hover be passed as `hoverContent`
*/
function MBHover(_a) {
    var onHoverStart = _a.onHoverStart, onHoverEnd = _a.onHoverEnd, style = _a.style, hoverContent = _a.hoverContent, hoverStyle = _a.hoverStyle, hoverContainerStyle = _a.hoverContainerStyle, children = _a.children, otherProps = __rest(_a, ["onHoverStart", "onHoverEnd", "style", "hoverContent", "hoverStyle", "hoverContainerStyle", "children"]);
    var _b = (0, react_1.useState)(false), isHovered = _b[0], setIsHovered = _b[1];
    var onMouseEnter = function () {
        setIsHovered(true);
        onHoverStart === null || onHoverStart === void 0 ? void 0 : onHoverStart();
    };
    var onMouseLeave = function () {
        setIsHovered(false);
        onHoverEnd === null || onHoverEnd === void 0 ? void 0 : onHoverEnd();
    };
    return (react_1.default.createElement("div", { style: hoverContainerStyle, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        react_1.default.createElement(view_1.MBView, __assign({}, otherProps, { style: [style, isHovered && hoverStyle] }),
            children,
            isHovered && hoverContent)));
}
