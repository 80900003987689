"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepeatEveryLabel = RepeatEveryLabel;
var react_1 = __importStar(require("react"));
var iconV2_1 = require("@shared/components/image/iconV2");
var row_1 = require("@shared/components/list/row");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function RepeatEveryLabel(_a) {
    var intervalValue = _a.intervalValue, intervalPluralStringKey = _a.intervalPluralStringKey, isPressable = _a.isPressable, onPress = _a.onPress;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(view_1.MBView, { paddingHorizontal: 3, paddingTop: 3 },
            react_1.default.createElement(typography_1.MBTextBody2Bold, { color: colors.foregroundActive }, (0, i18n_1.i18n)('card.blocks.recurrenceInput.actionSheet.repeatEvery'))),
        react_1.default.createElement(row_1.MBListRow, { title: (0, i18n_1.i18n)(intervalPluralStringKey, {
                count: intervalValue,
            }), image: react_1.default.createElement(view_1.MBView, { fill: true, center: true },
                react_1.default.createElement(iconV2_1.MBIconV2, { name: "repeat", color: colors.accent })), isHidingPressableIndicator: isPressable, onPress: onPress })));
}
