"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformsSheet = SubformsSheet;
var react_1 = __importStar(require("react"));
var bottomSheet_1 = require("@shared/components/bottomSheet");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var modal_1 = require("@shared/components/multiSelect/modal");
var view_1 = require("@shared/components/view");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var SelectSubform_1 = require("./SelectSubform");
function SubformsSheet(_a) {
    var containerRef = _a.containerRef, blockLabel = _a.blockLabel, workflowTemplate = _a.workflowTemplate, onClose = _a.onClose, onSelect = _a.onSelect, navigateToAdd = _a.navigateToAdd;
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var fixedWidth = isDesktop ? modal_1.FIXED_WIDTH : null;
    var renderTitleBar = (0, react_1.useCallback)(function () {
        return react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: blockLabel, rightIcon: "exit", onRightIconPress: onClose });
    }, [onClose, blockLabel]);
    return (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: containerRef, layout: {
            offset: { x: (0, view_1.grid)(5), y: (0, view_1.grid)(1) },
            minWidth: fixedWidth === null || fixedWidth === void 0 ? void 0 : fixedWidth.width,
            minHeight: (0, view_1.grid)(48),
        }, onClose: onClose, renderTitleBar: !isDesktop ? renderTitleBar : undefined },
        react_1.default.createElement(view_1.MBView, { style: fixedWidth, fill: true },
            react_1.default.createElement(SelectSubform_1.SelectSubform, { workflowTemplate: workflowTemplate, onSelect: onSelect, navigateToAdd: navigateToAdd }))));
}
