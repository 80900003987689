"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedCardsList = RelatedCardsList;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var scrollview_1 = require("@shared/components/scrollview");
var opacity_1 = require("@shared/components/touchable/opacity");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var i18n_1 = require("@shared/util/i18n");
var cards_1 = require("../cards");
var MBInlineCardList_1 = require("../cards/MBInlineCardList");
var RelatedCardsHeader_1 = require("./RelatedCardsHeader");
function RelatedCardsList(_a) {
    var block = _a.block, cards = _a.cards, totalCardsNubmer = _a.totalCardsNubmer, onShowMore = _a.onShowMore, onEditCards = _a.onEditCards, onPressCard = _a.onPressCard, onRemoveCard = _a.onRemoveCard;
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var label = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isEmpty)(cards) && !(0, lodash_1.isNil)(block.label)) {
            return block.label;
        }
        else if (!(0, lodash_1.isEmpty)(cards) && !(0, lodash_1.isNil)(block.label)) {
            return "".concat(block.label, " (").concat(totalCardsNubmer, ")");
        }
        else if (!(0, lodash_1.isEmpty)(cards) && (0, lodash_1.isNil)(block.label)) {
            return "(".concat(totalCardsNubmer, ")");
        }
        else {
            return '';
        }
    }, [block, totalCardsNubmer, cards]);
    var cardsToRender = (0, react_1.useMemo)(function () {
        if (!onShowMore) {
            return cards;
        }
        else {
            return cards === null || cards === void 0 ? void 0 : cards.slice(0, cards_1.MAX_CARDS_TO_SHOW);
        }
    }, [cards, onShowMore]);
    var displayShowMore = onShowMore && (cards === null || cards === void 0 ? void 0 : cards.length) > cards_1.MAX_CARDS_TO_SHOW;
    var scrollViewMaxHeight = (0, react_1.useMemo)(function () {
        if (!isDesktop) {
            return null;
        }
        if (displayShowMore) {
            return (0, view_1.grid)(80);
        }
        else {
            return (0, view_1.grid)(35);
        }
    }, [displayShowMore, isDesktop]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(view_1.MBView, { fullWidth: true, paddingTop: 1 },
            react_1.default.createElement(RelatedCardsHeader_1.RelatedCardsHeader, { label: label, onEditCards: onEditCards }),
            react_1.default.createElement(scrollview_1.MBScrollView, { style: { maxHeight: scrollViewMaxHeight } },
                react_1.default.createElement(MBInlineCardList_1.MBInlineCardList, { cards: cardsToRender, onPressCard: onPressCard, onRemoveCard: onRemoveCard }))),
        displayShowMore && (react_1.default.createElement(opacity_1.MBTouchableOpacity, { onPress: onShowMore },
            react_1.default.createElement(view_1.MBView, { centerHorizontally: true, paddingTop: 1 },
                react_1.default.createElement(typography_1.MBTextBody2Bold, null, (0, i18n_1.i18n)('card.blocks.cardsInput.overflow', {
                    count: cards.length - cards_1.MAX_CARDS_TO_SHOW,
                })))))));
}
