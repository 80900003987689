"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockDateContent = MBCardInputBlockDateContent;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var form_1 = require("@shared/components/blocks/contexts/form");
var utils_1 = require("@shared/components/blocks/utils");
var opacity_1 = require("@shared/components/touchable/opacity");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
var date_1 = require("@shared/util/date");
var types_1 = require("./types");
function MBCardInputBlockDateContent(_a) {
    var block = _a.block, pickerMode = _a.pickerMode, onOpenPicker = _a.onOpenPicker, onClosePicker = _a.onClosePicker;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var value = values[block.name];
    var openDatePicker = (0, react_1.useCallback)(function () { return onOpenPicker(types_1.PickerMode.Date); }, [onOpenPicker]);
    var openTimePicker = (0, react_1.useCallback)(function () { return onOpenPicker(types_1.PickerMode.Time); }, [onOpenPicker]);
    if (!value) {
        return react_1.default.createElement(TypoComponent, { color: colors.foregroundInactive }, block.placeholder);
    }
    if (block.fieldType === 'time') {
        return react_1.default.createElement(TypoComponent, { color: colors.foregroundActive }, (0, date_1.formatTime)(value));
    }
    if (block.fieldType === 'date') {
        return (react_1.default.createElement(TypoComponent, { color: colors.foregroundActive }, (0, date_1.formatDateWithYear)(value)));
    }
    return (react_1.default.createElement(view_1.MBView, { row: true, centerVertically: true },
        react_1.default.createElement(opacity_1.MBTouchableOpacity, { style: [
                styles.touchable,
                react_native_1.Platform.OS !== 'web' && pickerMode === types_1.PickerMode.Date && styles.highlighted,
            ], onPress: pickerMode === types_1.PickerMode.Date ? onClosePicker : openDatePicker },
            react_1.default.createElement(TypoComponent, { color: colors.foregroundActive }, (0, date_1.formatDateWithYear)(value))),
        react_1.default.createElement(view_1.MBView, { flex: true }),
        react_1.default.createElement(opacity_1.MBTouchableOpacity, { style: [
                styles.touchable,
                react_native_1.Platform.OS !== 'web' && pickerMode === types_1.PickerMode.Time && styles.highlighted,
            ], onPress: pickerMode === types_1.PickerMode.Time ? onClosePicker : openTimePicker },
            react_1.default.createElement(TypoComponent, { color: colors.foregroundActive }, (0, date_1.formatTime)(value)))));
}
var styles = react_native_1.StyleSheet.create({
    touchable: {
        paddingVertical: (0, view_1.grid)(1),
        paddingHorizontal: (0, view_1.grid)(1.5),
        marginLeft: -(0, view_1.grid)(1.5),
    },
    highlighted: {
        backgroundColor: styles_1.Colors.v2.gray05,
        borderRadius: (0, view_1.grid)(1),
    },
});
