"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.opacity = void 0;
exports.MBSeparator = MBSeparator;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
// Note: on web sub pixel heights sometimes don't show up.
// Therefore we have higher separators on web, but instead
// make the color somewhat lighter so that the separators
// don't look as big.
var thickness = react_native_1.Platform.select({ web: 1, default: 0.5 });
exports.opacity = react_native_1.Platform.select({
    web: 0.25,
    default: 0.5,
});
/*
    A thin horizontal line filling its width
*/
function MBSeparator(_a) {
    var vertical = _a.vertical, color = _a.color;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (!!vertical) {
        return (react_1.default.createElement(view_1.MBView, { style: {
                opacity: exports.opacity,
                width: thickness,
                alignSelf: 'stretch',
                backgroundColor: color || colors.foregroundInactive,
            } }));
    }
    return (react_1.default.createElement(view_1.MBView, { style: {
            opacity: exports.opacity,
            height: thickness,
            width: '100%',
            backgroundColor: color || colors.foregroundInactive,
        } }));
}
