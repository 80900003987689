"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockGeolocation = MBCardInputBlockGeolocation;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var use_layout_animation_1 = require("use-layout-animation");
var form_1 = require("@shared/components/blocks/contexts/form");
var utils_1 = require("@shared/components/blocks/utils");
var opacity_1 = require("@shared/components/touchable/opacity");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var base_1 = require("../input/base");
var utils_2 = require("../input/utils");
var content_1 = require("./content");
var promptBottomSheetContent_1 = require("./promptBottomSheetContent");
var utils_3 = require("./utils");
function MBCardInputBlockGeolocation(_a) {
    var _this = this;
    var block = _a.block;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var currentUserId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentUserId; });
    var containerRef = (0, react_1.useRef)();
    var _b = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _b.setFieldValue, setFieldError = _b.setFieldError, values = _b.values;
    var geolocation = values[block.name];
    var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
    var _c = (0, react_1.useState)(false), showingPrompt = _c[0], setShowingPromt = _c[1];
    var showPrompt = (0, react_1.useCallback)(function () { return setShowingPromt(true); }, []);
    var hidePrompt = (0, react_1.useCallback)(function () { return setShowingPromt(false); }, []);
    var clearValue = (0, react_1.useCallback)(function () { return setFieldValue(block.name, undefined); }, [setFieldValue, block.name]);
    var fetchCurrentGeolocation = (0, utils_3.useFetchCurrentGeolocation)();
    var handleFetchLocation = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var coords, updatedGeolocation, _error_1, error, platformKey;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    hidePrompt();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    clearValue();
                    return [4 /*yield*/, fetchCurrentGeolocation()];
                case 2:
                    coords = _a.sent();
                    updatedGeolocation = {
                        userId: currentUserId,
                        date: new Date().toISOString(),
                        accuracy: coords.accuracy,
                        coords: {
                            lat: coords.latitude,
                            lon: coords.longitude,
                        },
                    };
                    setFieldValue(block.name, updatedGeolocation);
                    return [3 /*break*/, 4];
                case 3:
                    _error_1 = _a.sent();
                    error = _error_1;
                    // GeolocationPositionError.PERMISSION_DENIED = 1
                    if (error.code === 1) {
                        platformKey = react_native_1.Platform.select({ web: 'web', default: 'native' });
                        setFieldError(block.name, (0, i18n_1.i18n)("card.blocks.geolocationInput.permissionDeniedError.".concat(platformKey)));
                    }
                    else {
                        setFieldError(block.name, error.message);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        hidePrompt,
        setFieldValue,
        setFieldError,
        currentUserId,
        block.name,
        fetchCurrentGeolocation,
        clearValue,
    ]);
    var empty = !geolocation;
    var renderLabel = (0, react_1.useMemo)(function () { return (!empty ? utils_2.renderLabel : null); }, [empty]);
    (0, use_layout_animation_1.useImmediateLayoutAnimation)([!!geolocation]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(opacity_1.MBTouchableOpacity, { ref: containerRef, disabled: !empty, onPress: showPrompt },
            react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, renderLabel: renderLabel }, empty ? (react_1.default.createElement(TypoComponent, { color: colors.foregroundInactive }, block.placeholder)) : (react_1.default.createElement(content_1.GeolocationContent, { geolocation: geolocation, onClear: clearValue })))),
        showingPrompt && (react_1.default.createElement(promptBottomSheetContent_1.PromptBottomSheetContent, { containerRef: containerRef, onPress: handleFetchLocation, onClose: hidePrompt }))));
}
